import WelcomePage from 'components/Dashboard/WelcomePage';
import Header from 'components/Layout/Common/Header';

export default function Home() {
  return (
    <>
      <Header title='Home' />
      <WelcomePage />
    </>
  );
}
