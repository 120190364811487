import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import LeftHeader from 'components/HeaderContent/LeftHeader';
import { PAGE_NAME, getPageURL } from 'services/opsPortal.service';

const Header = (props) => {
  function tToggle() {
    const { body } = document;
    if (window.screen.width <= 998) {
      body.classList.toggle('sidebar-enable');
    } else {
      body.classList.toggle('vertical-collpsed');
      body.classList.toggle('sidebar-enable');
    }
  }

  const { title = '', buttonAction, hasBackButton = false } = props;

  return (
    <React.Fragment>
      <header id='page-topbar'>
        <div className='navbar-header'>
          <div className='d-flex col-12'>
            <div className='navbar-brand-box d-lg-none d-md-block'>
              <Link
                href={getPageURL({
                  pageName: PAGE_NAME.HOME.label
                })}
                legacyBehavior
              >
                <span className='logo logo-light logo-sm'>
                  <Image
                    src={'/images/hafla-logo.png'}
                    alt='hafla logo'
                    height={22}
                    width={22}
                  />
                </span>
              </Link>
            </div>

            <button
              type='button'
              onClick={() => {
                tToggle();
              }}
              className='btn btn-sm px-3 font-size-16 header-item '
              id='vertical-menu-btn'
            >
              <i className='fa fa-fw fa-bars' />
            </button>
            <div
              className='d-flex justify-content-between align-items-center'
              style={{ width: '100%' }}
            >
              <div className='d-none d-lg-flex align-items-center'>
                <LeftHeader
                  title={title}
                  buttonAction={buttonAction}
                  hasBackButton={hasBackButton}
                />
              </div>
              <div className='d-flex d-lg-none align-items-center'>
                <LeftHeader
                  buttonAction={buttonAction}
                  hasBackButton={hasBackButton}
                />
              </div>
              <div className='d-flex align-items-center me-1'>
                {props.children}
              </div>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
