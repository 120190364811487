const LeftHeader = (props) => {
  const { title = '', buttonAction, hasBackButton = false } = props;
  return (
    <div className='d-flex align-items-center'>
      {hasBackButton ? (
        <button
          style={{ border: 'none', background: 'white' }}
          className='me-3'
          onClick={buttonAction}
        >
          <i className='fas fa-chevron-left pr-2 mr-4 cursor-pointer' />
        </button>
      ) : null}
      <span className='fs-4 lh-24 fw-medium'>{title}</span>
    </div>
  );
};

export default LeftHeader;
