const plannerPersonalDashBoard = {
  hideBreadCrumb: true,
  dashboard: {
    title: 'Planner Home',
    description:
      'Everything that the planner needs to know and is very much planner focused',
    params: { ops_user_id: null },
    queryParameters: {
      questionNo: 232,
      theme: 'transparent',
      bordered: false,
      titled: false
    }
  }
};

const dashboardList = [
  {
    title: 'Summary Dash',
    description: 'Summary Dash covering Month-to-Date Achievement',
    queryParameters: {
      questionNo: 45,
      theme: 'transparent',
      bordered: false,
      titled: false
    }
  },

  {
    title: 'Outstanding Payments',
    description: 'Outstanding Payments',
    queryParameters: {
      questionNo: 334,
      theme: 'transparent',
      bordered: false,
      titled: false
    }
  },
  {
    title: 'Planner Event Pipeline DashBoard',
    description: 'where are you',
    params: { ops_user_id: null },
    queryParameters: {
      questionNo: 595,
      theme: 'transparent',
      bordered: false,
      titled: false
    }
  },
  {
    title: 'Live SFA Dashboard',
    description:
      'This captures the soft aspects based on which the SFA score will be calculated',
    queryParameters: {
      questionNo: 40,
      theme: 'transparent',
      bordered: false,
      titled: false
    }
  },
  {
    title: 'Delight Team Performance (Stock)',
    description:
      'This is a summary of all tickets that are open at any point in time',
    queryParameters: {
      questionNo: 37,
      theme: 'transparent',
      bordered: false,
      titled: false
    }
  },
  {
    title: 'Planner Helper Dashboard',
    description:
      'It is a helper dashboard that will help them do their daily work more efficiently',
    queryParameters: {
      questionNo: 35,
      theme: 'transparent',
      bordered: false,
      titled: false
    }
  },
  {
    title: 'Planner Board',
    description:
      'Use this planner board to plan your perfect event. Plan + Product + Partner + Price = a winning quote',
    queryParameters: {
      questionNo: 306,
      theme: 'transparent',
      bordered: false,
      titled: false
    }
  },
  {
    title: 'Delight Team Performance (Flow)',
    description: 'Delight Team Performance (Flow)',
    queryParameters: {
      questionNo: 36,
      theme: 'transparent',
      bordered: false,
      titled: false
    }
  },
  {
    title: 'Partner Bid Responses',
    description:
      'This is a consolidated view for all responses from the partners submitted on all requested bids.',
    queryParameters: {
      questionNo: 271,
      theme: 'transparent',
      bordered: false,
      titled: false
    }
  },
  {
    title: 'Order Book',
    description: 'This is a consolidated view of all upcoming orders',
    queryParameters: {
      questionNo: 269,
      theme: 'transparent',
      bordered: false,
      titled: false
    }
  },
  {
    title: 'Vertical Dashboard',
    description:
      'Summary of confirmed orders at a vertical level, incorporates only product line item level information, omits order specific data.',
    queryParameters: {
      questionNo: 267,
      theme: 'transparent',
      bordered: false,
      titled: false
    }
  },
  {
    title: 'Event Profile Action & Allocation Dashboard',
    description: 'Event Profile Action & Allocation Dashboard.',
    queryParameters: {
      questionNo: 694,
      theme: 'transparent',
      bordered: false,
      titled: false
    }
  }
];

export { dashboardList, plannerPersonalDashBoard };
