import config from 'config';
import { Fragment, useEffect, useState } from 'react';

import MetabaseDashboard from 'components/Dashboard/MetabaseDashboard';
import Header from 'components/Layout/Common/Header';
import { plannerPersonalDashBoard } from 'config/metabase';
import { getAuthIdentity } from 'services/identity.service';

const WelcomePage = () => {
  const [planner, setPlanner] = useState(null);
  useEffect(() => {
    setPlanner(getAuthIdentity());
  }, []);

  plannerPersonalDashBoard.dashboard.params.ops_user_id = planner?.id;

  const showPlannerDashboard = config.isProduction && planner?.id;
  const welcomeMessage = `Welcome ${planner?.name || ''}`;
  return (
    <Fragment>
      <div className='page-content'>
        <div className='text-center'>
          <p>{welcomeMessage}</p>
        </div>
        {showPlannerDashboard ? (
          <MetabaseDashboard {...{ ...plannerPersonalDashBoard }} />
        ) : (
          <Header title='Home'></Header>
        )}
      </div>
    </Fragment>
  );
};

export default WelcomePage;
